import React from "react";
import onClickOutside from "react-onclickoutside";

import "./styles.scss";
export class Search extends React.Component {
  constructor(props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      focused: false,
      value: this.props.value ? this.props.value : "",
      key: this.props.key ? this.props.key : "",
      submitted: false,
      filteredResultList: [],
    };
  }

  handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    let valueFilter = value.trim();
    let newFilteredResultList;
    if (valueFilter !== "") {
      newFilteredResultList = this.props.resultList.filter((w) => {
        const label =
          typeof w === "string" || w instanceof String ? w : w.label;
        const sku =
          typeof w === "string" || w instanceof String ? w : w.sku

        return label.toLowerCase().includes(valueFilter.toLowerCase()) || sku?.toLowerCase().includes(valueFilter.toLowerCase());
      });
    } else {
      newFilteredResultList = [];
    }
    this.setState({
      value,
      submitted: false,
      focused: true,
      filteredResultList: newFilteredResultList,
    });
  };

  handleKeyDown = (e) => {
    if (typeof this.props.handleKeyDown === 'function') {
      this.props?.handleKeyDown(e);
    }
  };

  handleSelect = (e) => {
    const value = e.target.dataset.value;
    const key = e.target.dataset.key;
    this.setState(
      {
        value,
        key,
        submitted: true,
        focused: false,
      },
      (_) => {
        this.props.handleSubmit(value);
        this.props.handleSubmitKey &&
          this.props.handleSubmitKey({
            key: key,
            label: value,
          });
      }
    );
  };

  handleClickOutside() {
    this.setState({
      focused: false,
    });
  }

  filterResults = () => {};

  cleanInput = () => {
    this.setState(
      {
        value: "",
        submitted: false,
        focused: false,
      },
      (_) => {
        this.props.handleSubmit(this.state.value);
      }
    );
  };

  handleFocus = () => {
    this.setState({
      focused: true,
    });
  };

  handleBlur = () => {
    this.setState({
      focused: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      submitted: true,
      focused: false,
    });
    this.props.handleSubmit(this.state.value);
  };

  render() {
    const {
      placeholder,
      // value
    } = this.props;

    return (
      <div
        className={
          this.state.focused ? "search-container focused" : "search-container"
        }
      >
        <div className="search-input-wrapper">
          <form onSubmit={this.handleSubmit}>
            <i className="search-icon-desktop icon-iconos_buscar"></i>
            <i
              onClick={this.handleBlur}
              className={
                this.state.focused
                  ? "search-icon-mobile-focused icon-back"
                  : "search-icon-mobile icon-iconos_buscar"
              }
            ></i>
            <input
              className="search-input"
              placeholder={placeholder}
              value={this.state.value}
              onChange={this.handleChange}
              onFocus={this.handleFocus}
              onKeyDown={this.handleKeyDown}
              data-automation-id="search-input"
            ></input>
            {this.state.value && this.state.value.length > 0 && (
              <div
                className="search-close-wrapper"
                onClick={this.cleanInput}
                data-automation-id="search-cancel"
              >
                <i className="search-icon-close icon-iconos_close2"></i>
              </div>
            )}
          </form>
        </div>
        {this.state.value.length > 2 &&
          this.state.focused &&
          this.state.filteredResultList.length > 0 &&
          !this.state.submitted && (
            <div className="search-results-desktop">
              <ul
                className="search-results-desktop-inner"
                data-automation-id="search-result-list"
              >
                {this.state.filteredResultList.map((item) => {
                  const key =
                    typeof item === "string" || item instanceof String
                      ? item
                      : item.key;
                  const value =
                    typeof item === "string" || item instanceof String
                      ? item
                      : item.label;
                  return (
                    <li
                      onClick={this.handleSelect}
                      key={key}
                      data-key={key}
                      data-value={value}
                      data-automation-id={key}
                    >
                      {value}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}

        {this.state.value.length > 2 &&
          this.state.focused &&
          this.state.filteredResultList.length > 0 &&
          !this.state.submitted && (
            <ul
              className="search-results-mobile"
              data-automation-id="search-result-list"
            >
              {this.state.filteredResultList.map((item) => {
                const key =
                  typeof item === "string" || item instanceof String
                    ? item
                    : item.key;
                const value =
                  typeof item === "string" || item instanceof String
                    ? item
                    : item.label;
                return (
                  <li
                    onClick={this.handleSelect}
                    key={key}
                    data-key={key}
                    data-value={value}
                    data-automation-id={key}
                  >
                    {value}
                  </li>
                );
              })}
            </ul>
          )}
        {/* <ul className="search-results">
              {this.state.filteredResultList.map(item =>{
                  return <li key={item}>{item}</li>
              })}
            </ul> */}
      </div>
    );
  }
}

export default onClickOutside(Search);
