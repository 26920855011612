import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";

import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import { Container, Box, Typography, FormControlLabel, Divider, Button, Grid, Switch, Card, CardContent } from '@material-ui/core';
import DeviceSelector from "components/Functionals/DeviceSelector";
import "./styles.scss";
import GuardItem from "shared/components/guard/guard-item/GuardItem";
import { GuardStatusService } from "services/GuardStatusService";
import { ParseDateTimeToday } from "helpers/Parsers";
import { WSService } from "services/WSServices";
import sound from '../Guard/sound/alarm.mp3';
import GuardHistory from "shared/components/guard/guard-history/GuardHistory";
import { AuthService } from "services/AuthService";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";

const GuardActivityView = () => {
    const history = useHistory();
    const [lastUpdate, setLastUpdate] = useState(new Date());
    const [alerts, setAlerts] = useState([]);
    const [devices, setDevices] = useState();
    const [newGuardId, setNewGuardId] = useState("")
    let audioAlarm = new Audio(sound);
    const [openHistory, setOpenHistory] = useState(false);
    const [deviceHandheldIdentifier, setDeviceHandheldIdentifier] = useState();
    const {
        loading,
        beforeSubmit,
        afterSubmit,
        errors,
        dealWithError,
    } = useRequest();
    const minutesCheckTokenTime = 5 ; // 5 minutos

    useTokenExpirationCheck(minutesCheckTokenTime);

    const receiveDevicesSelected = (data) => {
        if (data != undefined) {
            console.log(data);
            setDevices(data.devices);
            setAlerts([]);
            setTimeout(() => {
                beforeSubmit();
                reloadAlerts(data.devices);
            }, 1000);
        }
    }

    const reloadAlerts = (device = devices) => {
        GuardStatusService.getAlarmsGuardStatus({
            device_id: device,
            excludeStatus: "FALSE_ALARM|ATTEMPTED_THEFT|THEFT"
        }).then(history => {
            afterSubmit();
            setAlerts(history);
        });
    }

    const receiveEvent = useCallback((payload) => {
        const ws = WSService.getInstance();
        if (payload.method === "GUARD" && payload.room && ws.inRoom(payload.room)) {
            setNewGuardId(payload.params.guard_tag);
            reloadAlerts(devices);
            soundAlarm();
            setTimeout(() => {
                setNewGuardId("")
            }, 5000);
        }

    }, []);

    const soundAlarm = () => {
        audioAlarm.play()
    }

    const handleCloseDialog = () => {
        setOpenHistory(false);
    }

    const btnSetDeviceClick = () => {
        setDeviceHandheldIdentifier(document.getElementById('deviceHandheldIdentifier').value);
    }

    const btnEvaluateModalClick = () => {
        if(openHistory){
            setOpenHistory(false);
        }
    }

    const content = (
        <Container className="content">

            <Card mt={0} ml={0} className="card-main">
                <CardContent>
                    <DeviceSelector
                        enabled={true}
                        typeRead={"GUARD"}
                        refRead={""}
                        receiveEvent={receiveEvent}
                        receiveDevicesSelected={receiveDevicesSelected}
                        includeType={"LOSS_PREVENTION"}
                    />
                    <Grid container>
                        <Grid xs={12} lg={12} xl={12}>
                            <Typography className="textupdate" component="div" align='left'>
                                Última Alerta: {lastUpdate.toJSON().slice(0, 19).replace(/-/g, '/').replace(/T/g, ' ')}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <Grid container>
                <Grid xs={6}>
                    <div className="alerts">  Alertas ({alerts.length})</div>
                </Grid>
                <Grid xs={6}>
                    <Button className="history" onClick={() => setOpenHistory(true)}>{"ver historico --->"}</Button>
                </Grid>
            </Grid>

            {/* funcionalidad para android  */}
            <input id='deviceHandheldIdentifier' type="hidden" />
            <button style={{ display: "none" }} id="buttonSetDevice" onClick={btnSetDeviceClick}>See More</button>
            <button style={{ display: "none" }} id="buttonEvaluateModal" onClick={btnEvaluateModalClick}>See More</button>
            {/* funcionalidad para android  */}

            <div className="alarm-container">
                {alerts.length > 0 &&
                    alerts.map((alert) => {
                        return (<GuardItem key={alert.id} anim={true} reload={reloadAlerts} alert={alert} deviceHandheldIdentifier={deviceHandheldIdentifier}></GuardItem>);
                    })}
            </div>
            <GuardHistory devices={devices} open={openHistory} handleCloseDialog={handleCloseDialog}></GuardHistory>
        </Container>
    );


    return (
        <div className="device-detail-container">
            <AdminLayout
                headerTitle={i18n.t("GUARD")}
                headerOptions={[]}
                content={content}
                navHidden={true}
            ></AdminLayout>

            {loading && <Loader />}
        </div>
    );
};

export default GuardActivityView;
