import {
  ListItem, ListItemText, Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Avatar,
  CardHeader,
  IconButton,
  CardMedia,
  ListItemIcon,
  Collapse,
  List,
} from '@material-ui/core';
import Zoom from '@mui/material/Zoom';

import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import { Grid, ListItemButton } from '@mui/material';
import style from "./ListItemProductTag.module.scss";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


const ListItemProductTag = ({tag}) => {

  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (<>
    <ListItemButton onClick={handleClick} className={style["button-list"]}>
        <img src={tag.product?.image} className={style["image"]} />
      <ListItemText primary={tag.product?.name} className={style["text"]}/>
      <ListItemText primary={tag.sku} className={style["text-end"]} align="right"/>
      {open ? <ExpandLess /> : <ExpandMore />}
    </ListItemButton>
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        <ListItemButton sx={{ pl: 4 }}>
          <ListItemText primary={"EPC: " + tag.epc} />
        </ListItemButton>
      </List>
    </Collapse></>

  );
}

export default ListItemProductTag;