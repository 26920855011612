import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { DeviceService } from "services/DeviceService";

import useRequest from "hooks/useRequest";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import { Container, Box, Typography, FormControlLabel, Divider, Button, Grid, Switch, Card, CardContent, IconButton } from '@material-ui/core';
import DeviceSelector from "components/Functionals/DeviceSelector";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { WSService } from "services/WSServices";
import "./styles.scss";
import { ProductService } from "services/ProductService";
import ShelvingCard from "shared/components/shelving-card/ShelvingCard";
import { styled } from '@mui/material/styles';
import Estanteria4x from './../../../../assets/images/Estanteria4x.png'
import Toggle from "components/MainApp/atoms/Forms/Toggle";
import { TagService } from "services/TagService";
import ShelvingItem from "shared/components/shelving-card/ShelvingItem";
import useTokenExpirationCheck from "hooks/useTokenExpirationCheck";
let globalChannel = null;

const ShelvingActivityView2 = () => {
  const history = useHistory();
  const [loadingLocal, setLoadingLocal] = useState(false);
  const [device, setdevice] = useState(null);
  const [channel, setChannel] = useState(false);
  const [arrayTags, setArrayTags] = useState([]);
  const [arrayTagsSaving, setArrayTagsSaving] = useState([]);
  const [mapTags, setMapTags] = useState(new Map());
  const [arrayProducts, setArrayProducts] = useState([]);
  const [myTime, setMyTime] = useState(new Date());
  const [queue, setQueue] = useState(false);
  const [openModalAllProducts, setOpenModalAllProducts] = useState(false);
  const queueValue = useRef(false);
  const [lastUpdate, setLastUpdate] = useState(new Date());
  var saving = false;
  const minutesCheckTokenTime = 5 ; // 5 minutos

  useTokenExpirationCheck(minutesCheckTokenTime);


  useEffect(() => {
    var timerID = setInterval(() => {
      tick();
      //console.log(arrayTags);
    }, 200);

    return () => clearInterval(timerID);
  });



  const tick = () => {
    var date = new Date();
    setMyTime(date);
  }

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'block',
    height: '300%',
    marginLeft: '25vw',
    marginTop: '25vh',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  useEffect(() => {
    /*beforeSubmit();
    DeviceService.device(id).then((device) => {
      setdevice(device);
      afterSubmit();
    });*/
  }, []);



  const receiveEvent = useCallback((payload) => {
    //setTimeout(() => {

    const ws = WSService.getInstance();
    //console.log("Message from socket");
    console.log(payload);
    if (canParseJson(payload)) {
      payload = JSON.parse(payload);
    }

    if (payload.method == "SUBSCRIBE") {
      setChannel(payload.params.room);
    }

    if (payload.method === "SELFCHECKOUT" && payload.room && ws.inRoom(payload.room)) {
      if (payload.action === "resend") {
        arrayTags.splice(0, arrayTags.length);
      }
    }

    if (payload.method === "EVENT" && payload.room && ws.inRoom(payload.room)) {

      var antenna = 0;
      antenna = payload.params[0].antenna;
      setArrayTags(removeByAttr(arrayTags, "antenna", antenna));
      payload.params.forEach(param => {
        antenna = param.antenna;
        // arrayTags.splice(0, arrayTags.length);
        if (param.epc != "ACTION:CLEAN") {
          setLoadingLocal(false);
          var tag = {
            epc: param.epc,
            sku: param.sku,
            product: null,
            antenna: param.antenna
          }
          TagService.tag(param.epc).then((tagFound) => {
            tag["expiration"] = tagFound.variables.expiration;
          })
          searchProduct(tag.sku, tag)
          arrayTags.push(tag)
        }
      });
      console.log("ANTENA: " + antenna);
      updateListTags(arrayTags);
      /*if(!saving) {
        saving = true;
        setTimeout(() => {
          updateListTags();
        }, 4000);
      }*/
    }
    //}, 1000);
  }, []);

  const updateListTags = (arrayCompleto) => {
    console.log("UPDATEEEE");

    let uniqueTags = arrayCompleto.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.epc === value.epc
      )))

    setArrayTagsSaving([...uniqueTags]);
    saving = false;
    setArrayTags(arrayCompleto);
    setLastUpdate(new Date())
  }

  var removeByAttr = function (arr, attr, value) {
    var i = arr.length;
    while (i--) {
      if (arr[i]
        && arr[i].hasOwnProperty(attr)
        && (arguments.length > 2 && arr[i][attr] === value)) {

        arr.splice(i, 1);

      }
    }
    return arr;
  }

  const searchProduct = (sku, tag) => {
    if (arrayProducts && arrayProducts.length > 0) {
      const product = arrayProducts.find(prod => prod.sku == sku);
      tag.product = product;
    }

    if (!tag.product || tag.product == undefined) {
      ProductService.productBySKU(sku).then(prod => {
        tag.product = prod.results.results[0];
        arrayProducts.push(prod.results.results[0]);
      });
    }


  };

  useEffect(() => {
    console.log("SSSSSSss")
  }, [arrayTags])


  const refresh = () => {
    setLoadingLocal(true)
    const ws = WSService.getInstance();
    console.log("refresh channel: " + channel)
    ws.resetTags(channel);
  }

  const canParseJson = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  const [checked, setChecked] = useState();

  const handleToggleChange = () => {
    console.log("handleToggleChange")
    setChecked(!checked)

  }

  const disableToggle = () => {
    console.log("disableToggle")
  }

  const handleCloseModalAllProducts = () =>{
    setOpenModalAllProducts(false)
  }

  function compareByExpiration(a, b) {
    if(a != undefined && b != undefined){
      return getDateExpiration(a.expiration) - getDateExpiration(b.expiration);
    }else {
      return 0;
    }
  }

  const getDateExpiration = (expiration) => {
    try{
      var dateParts = expiration.split("/");
      return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]).getTime();
    }catch{
      return new Date(1,1,2099);
    }
  }

  const onEnter = () => {
    console.log("enter")
}

  const content = (
    <Container className="content">
      <Card mt={0} ml={0} >
        <CardContent>
          <DeviceSelector
            enabled={true}
            typeRead={"SELFCHECKOUT"}
            refRead={""}
            receiveEvent={receiveEvent}
            includeType={"SHELVING"}
          />

          <Grid container>
            <Grid xs={9} lg={9} xl={9}>
              <Typography className="textupdate" component="div" align='left'>
                Ultima Actualizacion: {lastUpdate.toJSON().slice(0, 19).replace(/-/g, '/').replace(/T/g, ' ')}
              </Typography>
            </Grid>
            <Grid xs={3} lg={3} xl={3}>
              <Button onClick={refresh} className="btnrefresh">ACTUALIZAR</Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <div style={{display:"flex", padding:"10px"}}>
            <div style={{marginRight:"10px"}}>
              Mostrar vencimientos
            </div>
            <Toggle
              handleChange={handleToggleChange}
              checked={checked}
              disabled={false}
              name="channel"
            />
          </div>
      <Box mt={1} ml={0} >
        <Grid container>
          <Typography gutterBottom className="textTotal">
            Total de productos
          </Typography>
          <div className="txtcantidad">{arrayTags.length}</div>
        </Grid>
      </Box>
      <Box className="image-est">
        {checked &&
          <img className="alert-icon" src={require(`./../../../../assets/images/AlertExpiration.png`)} alt="alert" onClick={() => setOpenModalAllProducts(true)}/>
        }
        <Box sx={{ display: 'box', flexDirection: 'column' }} pt={14} pl={3}>
          <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 2)} antenna={2} showVtoItems={checked}/>
          <Box pl={1}>
            <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 1)} antenna={1} showVtoItems={checked}/>
          </Box>
        </Box>

        <Box sx={{ display: 'box', flexDirection: 'column' }} pt={2.2} pl={3}>
          <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 4)} antenna={4} showVtoItems={checked} />
          <Box pl={1}>
            <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 3)} antenna={3} showVtoItems={checked}/>
          </Box>
        </Box>

        <Box sx={{ display: 'box', flexDirection: 'column' }} pt={2.8} pl={3}>
          <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 6)} antenna={6} showVtoItems={checked} />
          <Box pl={1}>
            <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 5)} antenna={5} showVtoItems={checked}/>
          </Box>
        </Box>

        <Box sx={{ display: 'box', flexDirection: 'column' }} pt={2.5} pl={3}>
          <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 8)} antenna={8} showVtoItems={checked}/>
          <Box pl={1}>
            <ShelvingCard listItems={arrayTagsSaving && arrayTagsSaving.filter(tag => tag.antenna == 7)} antenna={7} showVtoItems={checked} />
          </Box>
        </Box>
      </Box>

      <Dialog onClose={handleCloseModalAllProducts} open={openModalAllProducts}
      sx={{
        backdropFilter: "blur(5px) sepia(5%)",
        // 👇 Another option to style Paper
        "& .MuiDialog-paper": {
          borderRadius: "20px",
          width:"660px",
          minWidth:"660px",
          maxWidth: "660px",
          maxHeight:"1200px",
        },
      }}
      onEnter={() => onEnter()}>
        <DialogTitle>
          <Box sx={{ display: 'flex', flexDirection: 'column' }} >
            <img src={require(`./../../../../assets/images/AlertExpiration.png`)} alt="alert" style={{width: "55px", height: "45px"}} />
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', pb: 5, pl: 11, mt: -5 }}>
            <Typography className="tittle-dialog">
              Lista de productos
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', pl: 72, mt: -11 }}>
            <IconButton className="icon-close" onClick={handleCloseModalAllProducts}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Grid container className="grid-item">
          {arrayTags && arrayTags.length > 0 &&  arrayTags.sort(compareByExpiration).map((tag, index) => {
            return (
              <Grid item>
                <Grid style={{display:"flex"}}>
                  <img className="image-shelf" src={require(`../../../../../src/assets/images/estanteV${tag.antenna}.PNG`)} alt="fondo" />
                  <ShelvingItem item={tag.product} index={index} expiration={checked ? tag.expiration : null}></ShelvingItem>
                </Grid>
              </Grid>
            )
            })
          }
        </Grid>
      </Dialog>
    </Container>
  );

  return (
    <div className="device-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Expositor Inteligente - ITECTOR")}
        headerOptions={[]}
        content={content}
        navHidden={true}
      ></AdminLayout>
      {(loading || loadingLocal) && <Loader />}
    </div>
  );
};

export default ShelvingActivityView2;
