import { Grid } from '@mui/material';
import React, { useState } from "react";
import style from "./ItemTagSearch.module.scss"
import imageFile from "../../../assets/images/default_product.png";
import { convertHexRfidToBigInt } from "../../../helpers/stock/StockHelper"


const ItemTagSearch = ({product, tag}) => {

    const [imageError, setImageError] = useState(false);

    return (
        <Grid container className={style["grid-content"]} sx={{height: {xs:'164px', sm:'164px', md:'100px'}}}>
            <Grid md={5} sm={12} xs={12} >
                <div className={style["product-content"]}>
                    <img src={!product?.image || imageError ? imageFile : product.image}
                        onError={() => { setImageError(true) }} />
                    <div>
                        <div className={style["product-name"]}>
                            {product?.name}
                        </div>
                        <div className={style["product-sku"]}>
                            sku {product?.sku}
                        </div>
                    </div>
                </div>
                <Grid container className={style["font-data"]} sx={{marginTop:'10px', height: 'auto'}}>
                    <Grid xs={2} sm={1}  sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} >
                        TAG
                    </Grid>
                    <Grid  sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} >
                        {tag}
                    </Grid>
                </Grid>
                <Grid container className={style["font-data"]}>
                    <Grid xs={2} sm={1} sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} >
                        EPC
                    </Grid>
                    <Grid sx={{ display: { xs: 'flex', sm: 'flex', md: 'none' } }} >
                        {convertHexRfidToBigInt(tag)}
                    </Grid>
                </Grid>
            </Grid>
            <Grid md={4} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }} >
                <div className={style["font-data"]}>
                    <div>
                        TAG
                    </div>
                    <div >
                        {tag}
                    </div>
                </div>
            </Grid>
            <Grid md={3} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                <div className={style["font-data"]}>
                    <div>
                        EPC
                    </div>
                    <div>
                        {convertHexRfidToBigInt(tag)}
                    </div>
                </div>
            </Grid>

        </Grid>
    )
}

export default ItemTagSearch;