import i18n from "i18n";

const tagsItems = [
  {
    title: i18n.t("Impresión / Configuración"),
    arrow: true,
    to: "/admin/config_tags",
    permission: 'read_config_tag',
  },
  {
    title: i18n.t("Trazabilidad"),
    arrow: true,
    to: "/admin/tag-traceability",
    permission: 'read_config_tag',
  }
];

export const stepTags = {
  items: tagsItems,
  headerTitle: i18n.t("Tags"),
  navHidden: false,
  goBackTo: 0,
};