import Environment from "../environment"; 
import Api from './ApiService';

import { Category } from "../models/Attributes";


export const CategoryService = {
	categories: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/categories/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Category(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
	},

    createCategory: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/categories/`, 'POST', data
                ).then(data => {
                    resolve(new Category(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateCategory: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/categories/${id}/`, 'PATCH', data
                ).then(data => {
                    resolve(new Category(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    deleteCategory: (id) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/categories/${id}/`, 'DELETE'
                ).then(data => {
                    resolve();
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    publicCategories: (params) => {
        return Api.fetch(`${Environment.api}api/public/categories/`, 'GET', params);
    },
}
