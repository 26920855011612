import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import useRequest from "hooks/useRequest";

import { PublicService } from "services/PublicService.jsx";
import { LocationService } from "services/LocationService.jsx";

import { EventRepository } from "helpers/EventRepository";

import { steps } from "./data/steps";
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import StepProgress from "components/MainApp/atoms/Wizard/StepProgress";
import StepOne from "./Wizard/Step1";
import StepTwo from "./Wizard/Step2";
import StepThree from "./Wizard/Step3";
import AddDeviceSuccess from "../AddDeviceSuccess";

import "./styles.scss";
import { DeviceService } from "../../../../services/DeviceService";
import { DeviceTypeService } from "../../../../services/DeviceTypeService";

const initialDevice = {
  id: null,
  serial_number: "",
  type: null,
  name: "",
  enabled: true,
  device: null,
  device_type: null,
  node: null,
  location: null
};

const AddDevice = props => {
  const history = useHistory();
  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError
  } = useRequest();

  const [activeStep, setActiveStep] = useState("0");
  const [sentSuccess, setSentSuccess] = useState(false);
  const [device, setDevice] = useState(initialDevice);
  const [types, setTypes] = useState([]);
  const [device_types_child_all, setDeviceTypesChildAll] = useState([]);
  const [device_types_child, setDeviceTypesChild] = useState([]);
  const [businessnodes, setbusinessnodes] = useState([]);
  const [zones, setzones] = useState([]);
  const [sectors, setsectors] = useState([]);
  const [state, setState] = useState({
    item_files: [],
    changed: false,
  });
  const headerOptions = [];

  useEffect(() => {
    let types = [
      {
        label: "Lector móvil",
        value: "MOB_READER"
      },
      {
        label: "Lector fijo",
        value: "FIX_READER"
      },
      {
        label: "Impresora",
        value: "PRINTER"
      }
    ];
    setTypes(types);

    DeviceTypeService.device_types().then((device_types) => {
      setDeviceTypesChildAll(device_types);
    });

    beforeSubmit();
    let businessnodesAux;
    PublicService.nodes().then(response => {
      afterSubmit();
      businessnodesAux = response;
      response = response.map(r => {
        r.label = r.name;
        r.value = r.name;
        return r;
      });
      setbusinessnodes(response);
    });
  }, []);

  const analizeErrors = (message, errors = {}) => {
    if (Object.keys(errors).length === 0) {
      EventRepository.notificationSend({
        label: message,
        type: "error"
      });
    }

    if (["type"].includes(Object.keys(errors)[0])) {
      setActiveStep("0");
    }

    if (["serial_number", "name", "node"].includes(Object.keys(errors)[0])) {
      setActiveStep("1");
    }

    if (message.match('existe.*número.*serie')) {
      setActiveStep("1");
    }
  };

  const handleFinish = e => {
    e.preventDefault();

    beforeSubmit();
    let deviceAux = { ...device };
    deviceAux.type = deviceAux.type.value;
    deviceAux.node = deviceAux.node.code;
    deviceAux.location_id = deviceAux.location?.id ?? null;
    if(deviceAux?.device_type?.value){
      deviceAux.device_type_id = deviceAux.device_type.value;
    }else{
      deviceAux.device_type_id = device_types_child_all
        .filter(dev => dev.type === device.type.value)
        .map(d => d.id)[0];
    }

    const formData = new FormData();
    if (state?.item_files?.length > 0) {
      deviceAux.item_file = state.item_files[0];
      formData.append('item_file', deviceAux.item_file);
    }
    for (let key in deviceAux) {
      if (key !== 'item_file') {
        formData.append(key, deviceAux[key]);
      }
    }

    DeviceService.createDevice(formData)
      .then(device => {
        afterSubmit();
        setSentSuccess(true);
        setDevice(device);

        EventRepository.notificationSend({
          label: i18n.t("Se añadio dispositivo de manera exitosa."),
          type: "success"
        });
        history.push(`/admin/devices`);
      })
      .catch(error => {
        afterSubmit();
        dealWithError(error, "generic.error", (message, errors) => {
          analizeErrors(message, errors);
        });
      });
  };

  const goToAddAntenna = () => {
    history.push(`/admin/antenna/add`);
  };

  const handleGoBack = () => {
    history.push(`/admin/devices`);
  };

  const handleChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    let deviceAux = { ...device };
    deviceAux[name] = value;

    setDevice(deviceAux);
  };

  const mapZones = locations => {
    let zones = locations.filter(location => {
      return location.type === "ZONE";
    });

    zones = zones.map(zone => {
      zone.label = zone.name;
      zone.value = zone.id;
      return zone;
    });
    setzones(zones);
  };

  const mapSectors = locations => {
    let sectors = locations.filter(location => {
      return location.type === "AREA";
    });

    sectors = sectors.map(zone => {
      zone.label = zone.name;
      zone.value = zone.id;
      return zone;
    });

    setsectors(sectors);
  };

  const handleSelect = (selectedOption, type) => {
    let deviceAux = { ...device };
    switch (type) {
      case "device_type":
        deviceAux.device_type = selectedOption;
        setDevice(deviceAux);
        break;
      case "type":
        deviceAux.type = selectedOption;
        deviceAux.device_type = null;
        setDevice(deviceAux);

        // buscamos si hay algún device_type hijo para cargarlo en el combo
        const device_types_filtered = device_types_child_all
          .filter(dev => dev.parent && dev.parent.type === deviceAux.type.value)
          .map(dev2 => ({ label: dev2.type, value: dev2.id }));

        setDeviceTypesChild(device_types_filtered);

        break;
      case "node":
        deviceAux.node = selectedOption;
        deviceAux.zone = "";
        deviceAux.area = "";
        LocationService.locations({
          code: selectedOption.code,
          no_parent: "true",
          parent: null
        }).then(locations => {
          mapZones(locations.results);
        });
        setDevice(deviceAux);
        break;
      case "zone":
        deviceAux.zone = selectedOption;
        deviceAux.location = selectedOption;
        deviceAux.area = "";
        LocationService.locations({
          code: device.node.code,
          no_parent: "false",
          parent: selectedOption.id
        }).then(locations => {
          mapSectors(locations.results);
        });
        setDevice(deviceAux);
        break;
      case "sector":
        deviceAux.area = selectedOption;
        deviceAux.location = selectedOption;
        setDevice(deviceAux);
        break;

      default:
        break;
    }
  };

  const getConfirmationView = () => {
    return [
      {
        title: i18n.t("Nombre"),
        value: device.name
      },
      {
        title: i18n.t("Tipo"),
        value: device.type && device.type.value
      },
      {
        title: i18n.t("Numero de Serial"),
        value: device.serial_number
      },
      {
        title: i18n.t("Sucursal"),
        value: device.node && device.node.code
      },
      {
        title: i18n.t("Ubicacion"),
        value: device.location ? device.location.name : i18n.t("Sin Ubicacion")
      }
    ];
  };

  const validateStep1 = () => {
    if (device) {
      return (
        device.serial_number !== "" &&
        device.node !== null &&
        device.zone !== null &&
        device.node !== undefined &&
        device.node !== ""
      );
    } else {
      return false;
    }
  };

  const validateStep0 = () => {
    if (device) {
      if(device_types_child.length && device.device_type === null){
        return false;
      }
      return device.type !== null;
    } else {
      return false;
    }
  };

  const goToStep = step => {
    setActiveStep(step);
  };

  const handleChangeImage = (e, err) => {
    if (err) {
      setState({ errors: { image: err } });
      return;
    }

    const item_files = e.target?.files?.length
      ? [e.target.files[0]]
      : [];
    setState({
      item_files: item_files,
      changed: true
    });
  };

  const handleDeleteImage = (e) => {
    let item_files = e?.length ? Array.from(e) : [];
    setState({
      item_files: item_files,
      changed: true
    });
  };

  const headerTitle = sentSuccess ? "Dispositivo creado" : "Añadir dispositivo";

  const step0 = (
    <StepOne
      handleContinue={() => setActiveStep("1")}
      device={device}
      handleSelect={handleSelect}
      handleChange={handleChange}
      types={types}
      enableContinue={validateStep0()}
      error={errors}
      device_types_child = {device_types_child}
    ></StepOne>
  );

  const step1 = (
    <StepTwo
      handleContinue={() => setActiveStep("2")}
      goBack={() => goToStep("0")}
      handleChange={handleChange}
      handleSelect={handleSelect}
      enableContinue={validateStep1()}
      device={device}
      error={errors}
      nodes={businessnodes}
      zones={zones}
      sectors={sectors}
      handleChangeImage={handleChangeImage}
      handleDeleteImage={handleDeleteImage}
      files={state.item_files}
    ></StepTwo>
  );

  const step2 = (
    <StepThree
      handleFinish={handleFinish}
      goBack={() => goToStep("1")}
      deviceInfo={getConfirmationView()}
      handleChange={handleChange}
      error={errors}
    ></StepThree>
  );

  // const stepList = [step0, step1, step2];
  const stepList = {
    "0": step0,
    "1": step1,
    "2": step2
  };

  const addSuccess = (
    <div className={`add-business-node-success`}>
      <AddDeviceSuccess
        id={device && device.code}
        name={device && device.name}
        boldButtonFunc={goToAddAntenna}
      />
    </div>
  );

  const content = (
    <div className={`add-business-node-content`}>
      {!sentSuccess && (
        <div className={`add-business-node-wrapper`}>
          <StepProgress steps={steps} activeStep={activeStep} />
          <div className={`add-business-node-step-wrapper`}>
            {stepList[activeStep]}
          </div>
        </div>
      )}
    </div>
  );

  return (
    <div className="add-business-node-container">
      <AdminLayout
        headerTitle={i18n.t(headerTitle)}
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default AddDevice;
