import React from "react";
import PropTypes from "prop-types";

import "./styles.scss";
export class MainInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      value: this.props.value,
      maxCharacters: 255,
      usedCharacters: this.props.value ? this.props.value.length : 0
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== this.state.value) {
      this.setState({
        value: this.props.value,
        usedCharacters: this.props.value ? this.props.value.length : 0
      })
    }
  }

  handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    this.setState({
      value,
      usedCharacters: value.length
    });

    if (this.props.handleChange) this.props.handleChange(e);
  };

  handleBlur = (e) => {
    e.preventDefault();
    this.setState({
      focused: false,
    });
  };

  handleFocus = (e) => {
    e.preventDefault();
    this.setState({
      focused: true,
    });
  };

  showInputHanlder = () => {
    this.setState({
      focused: true,
    });
  };

  render() {
    const {
      label,
      required,
      name,
      disabled,
      error
    } = this.props;

    return (
      <div className="main-input-textarea-container">
          {!this.state.focused &&
            (this.state.value === undefined || this.state.value === "") && (
              <div className="main-input-textarea-label-placeholder">
                {label}
                {" "}
                {required && (
                  <span className="main-input-textarea-required-label">*</span>
                )}
              </div>
            )}
        <div className={`main-input-textarea-wrapper ${this.state.focused ? 'focused' :''} ${disabled ? 'disabled' :''} ${error ? 'error' :''}`}>
          

          <React.Fragment>
            {(this.state.focused ||
              (this.state.value !== undefined && this.state.value !== "")) && (
              <div className={`main-input-textarea-label ${this.state.focused ? 'focused' : ''}  ${error ? 'error' : ''}`}>
                {label}{" "}
                {required && (
                  <span className="main-input-textarea-required-label">*</span>
                )}
              </div>
            )}

            <textarea
              value={this.state.value}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              onFocus={this.handleFocus}
              id={name}
              name={name}
              maxLength='255'
              data-automation-id="textarea"
            ></textarea>
            <div className="main-input-textarea-help">
              {`${this.state.usedCharacters}/${this.state.maxCharacters}`}
            </div>
          </React.Fragment>
        </div>
        <div className="main-input-textarea-error">
          {error}
        </div>

        {disabled && <div className="main-input-textarea-overlay"></div>}
      </div>
    );
  }
}

MainInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  required: PropTypes.bool,
  handleChange: PropTypes.func,
};

export default MainInput;
