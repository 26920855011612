
import { AppBar, Card, CardContent, Chip, Dialog, Grid, IconButton, Menu, Toolbar, Typography } from "@material-ui/core";
import * as React from 'react';
import "./styles.scss"
import i18n from "i18n";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import Slide from '@mui/material/Slide';
import MoreIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MenuItem from '@mui/material/MenuItem';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ItemTagSearch from "shared/components/item-tag-search/ItemTagSearch";
import Button from '@mui/material/Button';
import { useState } from "react";
import GuardComment from "../guard-comment/GuardComment";
import useRequest from "hooks/useRequest";
import Loader from "components/MainApp/atoms/Loader";
import { GuardStatusService } from "services/GuardStatusService";
import { EventRepository } from "helpers/EventRepository";
import { getAlarmType } from "../Parser";
import { ParseDateTimeToday } from "helpers/Parsers";
import { DeviceService } from "services/DeviceService";
import { WSService } from "services/WSServices";

const GuardItemDetail = ({ open, handleCloseDialog, product, alert, showActions = true, deviceHandheldIdentifier=1 }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const [openComment, setOpenComment] = useState(false)
    const [newStateComment, setNewStateComment] = useState("")

    const date = ParseDateTimeToday(alert.created_at);
    const device = alert.device;
    const tag = alert.tag;

    const {
        loading,
        beforeSubmit,
        afterSubmit,
        errors,
        dealWithError,
    } = useRequest();


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseComment = () => {
        setOpenComment(false);
    }

    const changeStateAlarm = (newState) => {

        setNewStateComment(newState);
        setOpenComment(true);
    }

    const handleConfirmComment = (comment) => {
        setOpenComment(false)
        beforeSubmit();
        const data = { comments: comment != "" ? comment : "Sin comentarios", status: newStateComment };
        GuardStatusService.updateGuardStatus(alert.id, data)
            .then((guardStatus) => {
                afterSubmit();
                EventRepository.notificationSend({
                    label: i18n.t(
                        `${getAlarmType(guardStatus.status)} confirmada`
                    ),
                    type: "success",
                });
            })
            .catch((error) => {
                afterSubmit();
                dealWithError(error, "generic.error", (message, errors) => {
                    analizeErrors(message, errors);
                });
            });
    }

    const analizeErrors = (message, errors = {}) => {
        if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
                label: message,
                type: "error",
            });
        }
    };

    const findTag = () => {
        if(deviceHandheldIdentifier == 1){
            let message = "No hay handheld conectado";
            let errors = [];
            dealWithError(message, message, (message, errors) => {
                analizeErrors(message, errors);
            });
            return;
        }
        const ws = WSService.getInstance();
        ws.find(device?.serial_number, tag.rfid, deviceHandheldIdentifier)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleCloseDialog}
            className="dialog"
            PaperProps={{
                style: {
                    backgroundColor: '#fafafc',
                },
            }}
        >
            <AppBar position="static" className="appBarMui">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                    <div className="header-tittle">
                        Alarma
                    </div>
                    <IconButton
                        size="large"
                        aria-label="display more actions"
                        edge="end"
                        color="inherit"
                        className="more"
                        onClick={handleClick}
                    >
                        <MoreIcon handleClick={handleClick} />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={openMenu}
                        onClose={handleClose}
                        className="menu"
                    >
                        <MenuItem onClick={handleClose}>
                            <DeleteForeverIcon className="icon-trash" />
                            Eliminar Alarma
                        </MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>

            <div className="txtdatedetail">{date}</div>


            <Card mt={0} ml={0} className="card-device">
                <CardContent >
                    <Grid container>
                        <Grid xs={8} className="device-name">
                            {device?.name}
                        </Grid>
                        <Grid xs={4}>
                            <div className="txt-alarm-type">
                                <Chip label={getAlarmType(alert.status)} className={alert.status} />
                            </div>
                        </Grid>

                        <Grid xs={12}>
                            <div >
                                {device?.serial_number}
                            </div>
                        </Grid>
                        <Grid xs={12}>
                            <div className="zone">
                                {device?.location.path_name}
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            
            <div className="div-product-selected">
            <div className="label-product" style={{ marginLeft: "5vw", marginTop: "15px !important" }}>Producto Detectado </div>
            <Button className="buttonFindIcon" variant="contained" onClick={findTag}> </Button>
            </div>
            <div style={{ width: "90vw", marginLeft: "5vw", marginTop: "20px" }}>
                <ItemTagSearch product={product} tag={tag.rfid}></ItemTagSearch>
            </div>

            {showActions &&
                <div className="div-bottom">
                    <Grid container
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={6} align="center" >
                            <Button className="button-bottom" variant="contained" onClick={() => changeStateAlarm("FALSE_ALARM")}> Falsa alarma</Button>
                        </Grid>
                        <Grid item xs={6} align="center"  >
                            <Button className="button-bottom" variant="contained" onClick={() => changeStateAlarm("ATTEMPTED_THEFT")}> Intento de robo</Button>
                        </Grid>
                        <Grid item xs={12} align="center" >
                            <Button className="button-bottom" variant="contained" onClick={() => changeStateAlarm("THEFT")}> Robo</Button>
                        </Grid>
                    </Grid>
                </div>
            }

            <div className="label-product" style={{ marginLeft: "5vw", marginTop: "15px !important" }}>Comentario</div>

            <Card mt={0} ml={0} className="card-comments">
                <CardContent >
                    <Grid container>
                        <Grid xs={12} className="device-name">
                            {alert?.comments}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <GuardComment open={openComment} handleClose={handleCloseComment} newState={newStateComment} handleConfirm={handleConfirmComment}></GuardComment>
            {loading && <Loader />}
        </Dialog>
    );
}

export default GuardItemDetail;