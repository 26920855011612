import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { LocationService } from "services/LocationService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";

import Panel from "components/MainApp/organisms/TogglePanel";
import LocationPanel from "components/MainApp/organisms/LocationPanel";
import Toggle from "components/MainApp/atoms/Forms/Toggle";
import DeleteModal from "../DeleteModal";
import "./styles.scss";
import { handleErrors } from "helpers/utils/errors";

const typeMap = {
  ZONE: "zona",
  AREA: "sector",
  SHELF: "estante",
  LEVEL: "nivel",
  PLACE: "ubicacion"
};

const articleMap = {
  ZONE: "la zona",
  AREA: "el sector",
  SHELF: "el estante",
  LEVEL: "el nivel",
  PLACE: "la ubicacion"
};

const LocationDetailView = props => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");

  const [locationId, setlocationId] = useState(null);
  const [loading, setLoading] = useState(false);

  const [location, setlocation] = useState(null);
  const [children, setchildren] = useState([]);
  const [node_code, setnode_code] = useState(null);
  const [deleteModalOpen, setdeleteModalOpen] = useState(false);

  useEffect(() => {
    if (!props.match.params.key) return;
    if (!props.location.state.node_code) return;

    const id = props.match.params.key;
    setlocationId(id);
    const node_code = props.location.state.node_code;
    setnode_code(node_code);
  }, []);

  useEffect(() => {
    setLoading(true);
    LocationService.location(locationId).then(location => {
      setLoading(false);
      setlocation(location);

      LocationService.locationsAll({
        node: node_code,
        parent: locationId
      }).then(locations => {
        const childrenAux = location.getLocationPanelsView(locations);
        setchildren(childrenAux);
      });
    });
  }, [locationId]);

  const headerOptions = [
    {
      icon: "icon-iconos_eliminar",
      name: "delete",
      handler: () => {
        setdeleteModalOpen(true);
      },
      tooltip: `Eliminar ${location && typeMap[location.type]}`
    },
    {
      icon: "icon-iconos_editar",
      name: "edit",
      handler: () => {
        history.push({
          pathname: `/admin/locations/edit/${locationId}`,
          state: {
            node_code: node_code,
            type: location.type,
            parent: location.parent ? location.parent.id : null
          }
        });
      },
      tooltip: `Editar ${location && typeMap[location.type]}`
    }
  ];

  const handleAddLocation = type => {
    history.push({
      pathname: `/admin/locations/add`,
      state: {
        node_code: node_code,
        type: type,
        parent: locationId
      }
    });
  };

  const goToLocation = (locationId, type) => {
    history.push({
      pathname: `/admin/locations/detail/${locationId}`,
      state: {
        node_code: node_code
      }
    });
    setlocationId(locationId);
  };

  const handleGoBack = () => {
    if (location.parent) {
      history.push({
        pathname: `/admin/locations/detail/${location.parent.id}`,
        state: {
          node_code: node_code
        }
      });
      setlocationId(location.parent.id);
    } else {
      history.push(`/admin/businessnodes/detail/${node_code}`);
    }
  };

  const handleChangeDispatchArea = () => {
    const data = { dispatch_area: !location.dispatch_area };

    setLoading(true);
    LocationService.updateLocation(location.id, data)
      .then(location => {
        setLoading(false);
        setlocation(location);
      })
      .catch(error => {
        setLoading(false);
      });
  };

  const closeDeleteModal = () => {
    setdeleteModalOpen(false);
  };

  const deleteLocation = () => {
    const parent = location.parent;
    setLoading(true);
    setdeleteModalOpen(false);

    LocationService.deleteLocation(locationId)
      .then(rta => {
        setLoading(false);
        if (parent) {
          history.push({
            pathname: `/admin/locations/detail/${parent.id}`,
            state: {
              node_code: node_code
            }
          });
          setlocationId(parent.id);
        } else {
          history.push(`/admin/businessnodes/detail/${node_code}`);
        }
      })
      .catch(async error => {
        setLoading(false);
        setdeleteErrorModal(true);
        const errorMsg = await handleErrors(error);
        setdeleteErrorMsg(errorMsg);
      });
  };

  const content = (
    <div className={"location-detail-content-wrapper"}>
      {location && (
        <div>
          <div className={"edit-location-toggle"}>
            <p>
              Generar <span>orden de salida</span> automaticamente.
            </p>
            <Toggle
              handleChange={handleChangeDispatchArea}
              checked={location.dispatch_area}
              name="dispatch_area"
            />
          </div>
          <Panel
            fields={location && location.getDescriptionPanelView()}
            title={"Descripción"}
          />
        </div>
      )}
      {children && (
        <LocationPanel
          key={children.id}
          title={children.title}
          type={children.subtitle}
          items={children?.locations || []}
          handleAdd={() => handleAddLocation(children.type)}
          handleClick={goToLocation}
        />
      )}
    </div>
  );
  return (
    <div className="location-detail-container">
      <AdminLayout
        headerTitle={
          location
            ? i18n.t(`Detalle de ${location && typeMap[location.type]}`)
            : ""
        }
        headerOptions={headerOptions}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <DeleteModal
        open={deleteModalOpen}
        handleClose={closeDeleteModal}
        handleCancel={closeDeleteModal}
        handleConfirm={deleteLocation}
        locationName={location && location.name}
        locationType={location && articleMap[location.type]}
      />
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>
      {loading && <Loader />}
    </div>
  );
};

export default LocationDetailView;
