import React from "react";
import CheckboxCostumAuth from "../../../../../Auth/atoms/forms/CheckboxCostum";
import {
  Tooltip,
} from 'react-tippy';
import "./styles.scss";

import default_image from "../../../../../../assets/images/default_product.png";

const tooltipStyle = {

  background: "#FFFFFF",
  boxShadow: "0px 3px 6px #00000029",
  height: "45px",
  padding: "0 15px",
  color: "#494F66",
  fontFamily: "Roboto-Light",
  fontSize: "18px",
  borderRadius: "15px",
  display: 'flex',
  alignItems: "center"
}
export class ProductTableItem extends React.Component {

  constructor(props){
    super(props);
    this.state={
      isSelected: this.props.isSelected,
      imgError: false
    }
  }
  handleSelect = () => {
    this.props.handleSelect(this.props.product.id);
  }

  render() {
    const {product, handleClick} = this.props
    return (
      <div className="table-item-container" data-automation-id="product-item" >
        {/* <CheckboxCostumAuth checked={this.props.isSelected} handleChange={this.handleSelect}></CheckboxCostumAuth> */}
        <div className="table-item-img"  data-product-id={product.id} onClick={handleClick}>
            <img
              alt="product"
              src={(this.state.imgError || !product.image) ? default_image : product.image}
              onError={() => { this.setState({imgError: true}) }}
            />
        </div>
        <div className="table-item-name"  data-product-id={product.id} onClick={handleClick} >  
          <Tooltip  html={<div style={tooltipStyle}>{product.name}</div>} title={product.name} followCursor={true} position="left" offset={25}> <p>{product.name}</p></Tooltip>
        </div>
        <div className="table-item-brand"  data-product-id={product.id} onClick={handleClick}> 
          <Tooltip  html={<div style={tooltipStyle}>{product.brand ? product.brand.name : null}</div>} followCursor={true} position="right" offset={25}> <p>{product.brand ? product.brand.name : '-'}</p></Tooltip>
         </div>
        <div className="table-item-code"  data-product-id={product.id} onClick={handleClick}> 
          <Tooltip  html={<div style={tooltipStyle}><p>{product.sku}</p></div>} followCursor={true} position="right" offset={25}> <p>{product.sku}</p></Tooltip>
        </div>
        <div className="table-item-category"  data-product-id={product.id} onClick={handleClick}>
            { product.categories.length > 0 ?
                product.categories.map((category) => {
                    return <Tooltip  key={category.id} html={<div style={tooltipStyle}><p>{category.name}</p></div>} followCursor={true} position="right" offset={25}> 
                             <div className="table-item-category-inner" key={category.id}> <p>
                                {category.name}
                                </p></div></Tooltip>})
                    :
                    <span>-</span>    
            }
        </div>
        <div className="table-item-stock"  data-product-id={product.id} onClick={handleClick}> {product.stock}</div>
        <div className="table-item-button" data-automation-id="select-product" data-product-id={product.id} onClick={handleClick}>
            <i className="icon-chevron-derecha"></i>
        </div>
      </div>
    );
  }
}

ProductTableItem.propTypes = {
};

export default ProductTableItem;