import React, { useState } from 'react'
import Step from 'components/MainApp/atoms/Wizard/Step'
import MainDropdownInput from 'components/MainApp/atoms/Forms/MainDropdown'
import { isMobile } from 'helpers/Mobile'
import TwoOptionModal from 'components/MainApp/atoms/Modals/TwoOptionModal'

import './styles.scss'

export const AddConfigTagStepThree = ({
  printer,
  handleSelect,
  goBack,
  handleFinish,
  error,
  printers,
  enableContinue,
  goBackFunc,
  handleClose,
  handlePersist,
  modalPersistOpen,
  onlyCloseModal
}) => {
  const handleConfirm = () => {
    handlePersist()
  }

  const handleCloseIntern = () => {
    handleClose()
  }

  const handleCloseIcon = () => {
    onlyCloseModal();
  }

  const mwidth = isMobile ? '100% ' : '322px'
  const modalContent = ''

  const stepContent = (
    <form onSubmit={handleFinish}>
      <div className="add-tag-wizard-step-three-content">
        <div className="add-tag-wizard-step-three-field" data-automation-id="input-printer">
          <MainDropdownInput
            required={true}
            id={'printer'}
            name="printer"
            selectedOption={printer ? printer : ''}
            handleSelection={(selectedOption) => handleSelect(selectedOption, 'printer')}
            maxLength={50}
            options={printers}
            placeholder={'Selecciona una impresora'}
            label={'Selecciona una impresora'}
            error={error.printer && error.printer}
          />
        </div>
        <div className={'add-tag-wizard-step-three-action-wrapper'}>
          <button
            type="submit"
            className={`add-tag-wizard-step-three-action-button ${!enableContinue && 'disabled'}`}
            onClick={enableContinue ? handleFinish : undefined}
          >
            IMPRIMIR
          </button>
        </div>
      </div>
    </form>
  )

  return (
    <div className="add-tag-wizard-step-three-container">
      <Step title="Selecciona una impresora" goBackFunc={goBackFunc} content={stepContent}></Step>
      <TwoOptionModal
        open={modalPersistOpen}
        title={'Interrumpir impresion de Tags'}
        subtitle={
          <span style={{ fontFamily: 'Ubuntu-Medium', color: '#676F8F' }}>
            ¿Desea salir y guardar el progreso?
          </span>
        }
        closeLabel={'SALIR SIN GUARDAR'}
        confirmLabel={'GUARDAR'}
        handleClose={handleCloseIntern}
        handleConfirm={handleConfirm}
        modalContent={modalContent}
        confirmDisabled={false}
        closeable={true}
        handleCloseIcon = {handleCloseIcon}
      ></TwoOptionModal>
    </div>
  )
}

export default AddConfigTagStepThree
