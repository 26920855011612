import Environment from "../environment";
import Api from './ApiService';
import { AuthService } from './AuthService.js';

import { Product } from "../models/Items";


export const ProductService = {
	products: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/products/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Product(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    productsByNode: (params) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/products/`, 'GET', params)
                .then(rta => {
                	rta.results = rta.results.map(item => new Product(item));
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    productsByNode2: (id, params) => {
      return new Promise((resolve, reject) => {
              return Api.fetch(`${Environment.api}api/nodes/${id}/products/`, 'GET', params)
                  .then(rta => {
                    rta.results = rta.results.map(item => new Product(item));
                      resolve(rta)
                  }).catch(error => {
                      reject(error);
                  });
          });
      },

    product: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/products/${id}/`, 'GET')
                .then(rta => {
                	rta.results = new Product(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    productRelatedItems: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/items/${id}/related_items`, 'GET')
                .then(rta => {
                    resolve(rta.related_items)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    productBySKU: (sku) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/products/`, 'GET', {sku:sku})
                .then(rta => {
                	rta.results = new Product(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },



    productByNode: (id) => {
		return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/nodes/${AuthService.getCurrentNodeCode()}/products/${id}/`, 'GET')
                .then(rta => {
                	rta.results = new Product(rta)
                    resolve(rta)
                }).catch(error => {
                    reject(error);
                });
        });
    },

    createProduct: (data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/products/`, 'POST', data
                ).then(data => {
                    resolve(new Product(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },


    createProductFormData: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/products/`, 'POST', formData
                ).then(data => {
                    resolve(new Product(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateProduct: (id, data) => {
        return new Promise((resolve, reject) => {
            return Api.fetch(`${Environment.api}api/products/${id}/`, 'PATCH', data
                ).then(data => {
                    resolve(new Product(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    updateProductFormData: (id, formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/products/${id}/`, 'PATCH', formData
                ).then(data => {
                    resolve(new Product(data));
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    uploadProducts: (formData) => {
        return new Promise((resolve, reject) => {
            return Api.fetchUpload(`${Environment.api}api/products/upload/`, 'POST', formData
                ).then(data => {
                    resolve(data);
                }).catch((error) => {
                    reject(error);
                }
            );
        });
    },

    downloadProducts: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/products/download/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    downloadTemplateProducts: (params) => {
        return new Promise((resolve, reject) => {
            return Api.fetchDownload(`${Environment.api}api/products/download_template/`, 'GET', params
                ).then(blob => {
                    resolve(blob);
                }).catch((error) => {
                    reject(error);
                });
        });
    },

    // Products name
    productsName: (params) => {
        return Api.fetch(`${Environment.api}api/search/products/`, 'GET', params);
    },

    itemBySKU: (sku) => {
        return Api.fetch(`${Environment.api}api/items/`, 'GET', {sku:sku});
    },
}
