import "./styles.scss";
import { useEffect, useState } from "react";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import i18n from "i18n";
import { TagsReader } from "shared/components/tags-reader/TagReader";
import { Container, List } from "@material-ui/core";
import { Grid } from "@mui/material";
import ItemTagSearch from "shared/components/item-tag-search/ItemTagSearch";
import ItemTraceability from "shared/components/item-traceability/ItemTraceability";
import { TagService } from "services/TagService";
import { ProductService } from "services/ProductService";
import { MovementService } from "services/MovementServkce";
import { AuthService } from "services/AuthService";
import { ParseDate } from "helpers/Parsers";
import { ParseMovement } from "helpers/ParseMovement";
import TableComponent from "shared/components/table/TableComponent";
import ModeStandbyIcon from "@mui/icons-material/ModeStandby";
import excel from "assets/images/excel.svg";
import excelwhite from "assets/images/excelwhite.svg";
import useRequest from "hooks/useRequest";
import Loader from "components/MainApp/atoms/Loader";
import FileSaver from "file-saver";
import { useHistory } from "react-router-dom";
import { isMobile } from "helpers/Mobile";

export const TraceabilityTags = () => {
  const { loading, beforeSubmit, afterSubmit } = useRequest();
  const [product, setProduct] = useState(null);
  const [tagSearched, setTagSearched] = useState("");
  const [listHistory, setListHistory] = useState([]);
  const [rows, setRows] = useState([]);
  const [tagID, setTagID] = useState(null);
  const history = useHistory();

  const headCells = [
    {
      id: "FECHA",
      numeric: false,
      disablePadding: true,
      label: "FECHA"
    },
    {
      id: "TIPODEORDEN",
      numeric: false,
      disablePadding: false,
      label: "TIPO DE ORDEN",
      type: "Chip"
    },
    {
      id: "ORDEN",
      numeric: false,
      disablePadding: false,
      label: "ORDEN"
    },
    {
      id: "MOTIVO",
      numeric: false,
      disablePadding: false,
      label: "MOTIVO"
    },
    {
      id: "RESPONSABLE",
      numeric: false,
      disablePadding: false,
      label: "RESPONSABLE"
    },
    {
      id: "SUCURSAL",
      numeric: false,
      disablePadding: false,
      label: "SUCURSAL"
    }
  ];

  useEffect(() => {
    if (listHistory) {
      const list = [];
      listHistory.forEach(movement => {
        list.push({
          FECHA: ParseDate(movement.date),
          TIPODEORDEN: ParseMovement.getMovementType(movement.movement_type),
          ORDEN: movement.order_code,
          MOTIVO: ParseMovement.getOrderKlass(movement.order_klass),
          RESPONSABLE: movement.owner,
          SUCURSAL: movement.node
        });
      });
      setRows(list);
    }
  }, [listHistory]);

  const handleSearch = cod => {
    beforeSubmit();
    TagService.tag(cod)
      .then(tag => {
        setTagSearched(cod);
        searchProduct(tag.sku, tag.id);
      })
      .catch(error => {
        setTagSearched(null);
        setProduct(null);
        afterSubmit();
      });
    return;
  };

  const searchProduct = (sku, tagID) => {

    ProductService.itemBySKU(sku)
      .then(prod => {
        setProduct(prod.results[0]);
        searchMovement(prod.results[0].id, tagID);
        setTagID(tagID);
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const searchMovement = (itemID, tagID) => {
    let nodeCode = AuthService.getCurrentNodeCode();
    const data = {
      tag_id: tagID,
      node: nodeCode,
      ordering: "m.updated_at desc"
    };

    MovementService.movementItemsHistory(itemID, data)
      .then(result => {
        setListHistory(result);
        console.log(result);
        afterSubmit();
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const handleDeleteAll = () => {
    setProduct(null);
  };

  const downloadReport = () => {
    if (!listHistory || !product) {
      return;
    }
    let nodeCode = AuthService.getCurrentNodeCode();
    beforeSubmit();
    const data = {
      tag_id: tagID,
      node: nodeCode,
      ordering: "m.updated_at desc",
      format_end: "excel"
    };

    MovementService.movementItemsHistoryDownload(product.id, data)
      .then(blob => {
        afterSubmit();
        FileSaver.saveAs(blob, "reporte.xls");
      })
      .catch(error => {
        afterSubmit();
      });
  };

  const headerButtons = (
    <div
      className="excel-button"
      onClick={downloadReport}
      style={{
        width: "30px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center"
      }}
    >
      <img style={{ height: "30px" }} src={isMobile ? excelwhite : excel} />{" "}
    </div>
  );

  const content = (
    <Container className="tag-traceability-container">
      <div className="tag-traceability-grid-container">
        <TagsReader enabledRead={true} handleSearch={handleSearch} />
      </div>
      <div className="tag-traceability-content">
        <Grid sx={{ display: { xs: "none", sm: "none", md: "contents" } }}>
          <div className="items-buttons-wrapper">
            <div className="items-button" onClick={handleDeleteAll}>
              LIMPIAR
            </div>
          </div>
        </Grid>

        {product && listHistory && (
          <>
            <ItemTagSearch product={product} tag={tagSearched}></ItemTagSearch>

            {rows && (
              <TableComponent
                sx={{ display: { xs: "none", sm: "none", md: "flex" } }}
                headCells={headCells}
                rows={rows}
                LeftIcon={
                  <div className="table-icon">
                    <span className="circle">
                      <div></div>
                    </span>
                  </div>
                }
              />
            )}

            <List sx={{ display: { xs: "flex", sm: "flex", md: "none" } }}>
              {listHistory &&
                listHistory.map(movement => {
                  return (
                    <ItemTraceability
                      date={ParseDate(movement.date)}
                      motive={ParseMovement.getOrderKlass(movement.order_klass)}
                      orderNumber={movement.order_code}
                      responsible={movement.owner}
                      node={movement.node}
                    />
                  );
                })}
              <div className="footer">
                <div className="items-buttons-wrapper">
                  <div className="items-button" onClick={handleDeleteAll}>
                    LIMPIAR
                  </div>
                </div>
              </div>
            </List>
          </>
        )}
      </div>
    </Container>
  );

  const goToBack = () => {
    history.goBack();
  };

  return (
    <div>
      <AdminLayout
        headerTitle={i18n.t("Trazabilidad")}
        headerOptions={[]}
        content={content}
        goBackFunc={goToBack}
        customButtons={headerButtons}
        navHidden={true}
      ></AdminLayout>
      {loading && <Loader />}
    </div>
  );
};

export default TraceabilityTags;
