import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import { StockService } from "services/StockService";
import { PublicService } from "services/PublicService";
import useRequest from "hooks/useRequest";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import ErrorModal from "components/MainApp/organisms/Stock/ErrorModal";
import LocalizationProductFilter from "./LocalizationProductFilter";

import "./styles.scss";
import LocalizationProductItem from "../../../../shared/components/LocalizationProductItem";
import DropDownTags from "shared/components/drop-down/DropDownTags";

import { Container, Grid } from "@material-ui/core";
import { stepLocalizations } from "views/Admin/ExtraMenu/data/stepLocalizations";
import { NotFoundItems } from "views/Admin/components/NotFoundItems";

export const LocalizationProductView = () => {
  const history = useHistory();
  const [mobilePanelOpen, setMobilePanelOpen] = useState(false);
  const paginationSize = 10;
  const [itemId, setItemId] = useState(null);
  const [listStockByPath, setListStockByPath] = useState(null);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [products_name, setProducts_name] = useState();

  const { loading, afterSubmit } = useRequest();

  const goBackModal = () => {
    history.push({
      pathname: "/admin/stock",
      state: { step: stepLocalizations }
    });
  };

  const handleSelectedItem = item => {
    if (item) {
      setMobilePanelOpen(false);
      setItemId(item.key);
      StockService.localization_product(item.key)
        .then(response => {
          afterSubmit();
          var i = 0,
            val,
            index,
            values = [],
            result = [];
          for (; i < response.length; i++) {
            if (response[i].stock.location) {
              val = response[i].stock.location.path;
              index = values.indexOf(val);
              if (index > -1) result[index].push(response[i]);
              else {
                values.push(val);
                result.push([response[i]]);
              }
            }
          }
          setListStockByPath(result);
        })
        .catch(_ => {
          setListStockByPath(null);
        });
    } else {
      setItemId(null);
      setListStockByPath(null);
    }
  };

  const tableContentNew = (
    <div className="outter-wrapper">
      <Container className="container-localization-products">
        <LocalizationProductFilter handleApply={handleSelectedItem} />

        {itemId && (
          <div className="grid-wrapper">
            <Grid className="grid-container">
              <LocalizationProductItem itemId={itemId} />
              {listStockByPath && (
                <>
                  {listStockByPath.map(item => {
                    return <DropDownTags listTags={item} />;
                  })}
                </>
              )}
            </Grid>
          </div>
        )}

        {!listStockByPath && !loading && (
          <NotFoundItems />
        )}
      </Container>
    </div>
  );

  return (
    <div className="load-locprod-container">
      <AdminLayout
        headerTitle={i18n.t("Localización de Productos")}
        headerOptions={[]}
        content={tableContentNew}
        goBackFunc={goBackModal}
        navHidden={true}
      ></AdminLayout>

      <ErrorModal
        open={openErrorModal}
        message={errorMessage}
        handleGoBack={e => {
          setOpenErrorModal(false);
        }}
        handleClose={e => {
          setOpenErrorModal(false);
        }}
        handleAddLocalization={e => {
          history.push("/admin/businessnodes");
        }}
        handleButtonClick={e => {
          setOpenErrorModal(false);
        }}
      />

      {loading && <Loader />}
    </div>
  );
};

export default LocalizationProductView;
