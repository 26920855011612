import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import i18n from "i18n";
import { TagService } from "services/TagService";

import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import GenericErrorModal from "components/MainApp/atoms/Modals/GenericErrorModal";
import Icon from "components/MainApp/atoms/Icon/icon";
import Panel from "components/MainApp/organisms/TogglePanel";

import "./styles.scss";

const ConfigTagDetailView = (props) => {
  const history = useHistory();
  const [deleteErrorModal, setdeleteErrorModal] = useState(false);
  const [deleteErrorMsg, setdeleteErrorMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const [configTag, setConfigTag] = useState(null);

  useEffect(() => {
    if (!props.match.params.key) return;
    const id = props.match.params.key;
    setLoading(true);
    TagService.configTag(id).then((configTag) => {
      setConfigTag(configTag);
      setLoading(false);
    });
  }, []);

  const deleteConfigTag = () => {
    setLoading(true);
    TagService.deleteConfigTag(configTag.id)
      .then((rta) => {
        history.push(`/admin/config_tags`);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setdeleteErrorModal(true);
        setdeleteErrorMsg("No se puede eliminar la plantilla");
      });
  };

  const headerOptions = [
    {
      icon: "icon-iconos_eliminar",
      name: "delete",
      handler: deleteConfigTag,
      tooltip: "Eliminar Plantilla",
    },
    {
        icon: 'icon-iconos_editar',
        name: 'editar',
        handler: () => {history.push(
            `/admin/config_tags/edit/${configTag.id}`
          )},
        tooltip: 'Editar Plantilla'
    },
  ];
  const headerOptionsIcons = [
    {
      icon: "dispositivos",
      name: "dispositivos",
      handler: () => {
        history.push(`/admin/tags/print/${configTag.id}`);
      },
      tooltip: "Imprimir",
    },
  ];
  const handleGoBack = () => {
    history.push(`/admin/config_tags`);
  };

  const content = (
    <div className={"businessnode-detail-content-wrapper"}>
      {configTag && (
        <Panel
          fields={configTag && configTag.getDescriptionPanelView()}
          title={i18n.t("Descripción")}
        />
      )}
      {configTag && (
        <Panel
          fields={configTag && configTag.getPrintPanelView()}
          title={i18n.t("Impresión")}
        />
      )}
    </div>
  );
  return (
    <div className="businessnode-detail-container">
      <AdminLayout
        headerTitle={i18n.t("Detalle de tag")}
        headerOptions={headerOptions}
        headerOptionsIcons={headerOptionsIcons}
        content={content}
        navHidden={true}
        goBackFunc={handleGoBack}
      ></AdminLayout>
      <GenericErrorModal
        open={deleteErrorModal}
        handleClose={() => setdeleteErrorModal(false)}
        error={deleteErrorMsg}
      ></GenericErrorModal>

      {loading && <Loader />}
    </div>
  );
};

export default ConfigTagDetailView;
