import React from "react";
import MainInput from "components/MainApp/atoms/Forms/MainInput";
import MainButtonInput from "components/MainApp/atoms/Forms/MainButtonInput";
import DragAndDropImage from "components/MainApp/atoms/Forms/DragAndDropImage";
import MultiSelectGeneric from "components/MainApp/atoms/Forms/MultiSelectInputGeneric";
import "./styles.scss";

const UserForm = ({
  user,
  handleSelectRole,
  handleChange,
  handleSelectImage,
  handleDeleteImage,
  handleSubmit,
  errors,
  continueEnabled,
  mode,
  businesses,
  selectedBusinesses,
  roles,
  selectedRole,
  handleChangeBusiness,
  handleChangeNodes,
  handleAddBusiness
}) => {
  return (
    <div className="user-form-content">
      <h1 className="user-form-title">Ingresa los datos</h1>
      <div className="user-form-field" data-automation-id="input-username">
        <MainInput
          label={"Nombre del usuario"}
          required={true}
          id={"user-username"}
          name="username"
          value={user ? user.username : ""}
          handleChange={handleChange}
          maxLength={100}
          error={errors?.username && errors.username}
        />
      </div>
      {/* {(mode !== 'edit') && <div
                className="user-form-field" data-automation-id="input-password">
                <MainInput
                    label={"Contraseña"}
                    required={true}
                    type={'password'}
                    id={"user-password"}
                    name="password"
                    value={user ? user.password : ''}
                    handleChange={handleChange}
                    maxLength={100}
                    error={errors?.password && errors.password}
                /></div>} */}

      <div className="user-form-field" data-automation-id="input-email">
        <MainInput
          label={"Email"}
          required={true}
          id={"user-email"}
          name="email"
          value={user ? user.email : ""}
          handleChange={handleChange}
          maxLength={100}
          error={errors?.email && errors.email}
        />
      </div>

      <div className="user-form-field" data-automation-id="input-roles">
        <MultiSelectGeneric
          id="roles"
          items={roles}
          required={true}
          label="Roles"
          selected={selectedRole}
          multiple={false}
          handleChange={handleSelectRole}
          error={errors?.roles_id && errors.roles_id}
        />
      </div>

      <h1 className="user-form-title">Empresas y sucursales asociadas</h1>

      {/* {selectedBusinesses.map((sb, index) => {
        return (
          <div key={sb.id} className="user-form-field">
            <div
              className="user-form-field"
              data-automation-id="input-business"
            >
              <MultiSelectGeneric
                items={businesses}
                required={true}
                label="Empresa"
                selected={sb.business}
                multiple={false}
                handleChange={(e) => handleChangeBusiness(e, index)}
              />
            </div>

            <div className="user-form-field" data-automation-id="input-nodes">
              <MultiSelectGeneric
                items={sb.nodes}
                required={true}
                label="Sucursales"
                selected={sb.selectedNodes}
                multiple={true}
                handleChange={(e) => handleChangeNodes(e, index)}
              />
            </div>
          </div>
        );
      })}

      <div className="add-business-button" onClick={handleAddBusiness}>
        Agregar empresa y sucursales
      </div> */}

      <div key={selectedBusinesses[0].id} className="user-form-field">
        <div className="user-form-field" data-automation-id="input-business">
          <MultiSelectGeneric
            id="business"
            items={businesses}
            required={true}
            label="Empresa"
            selected={selectedBusinesses[0].business}
            multiple={false}
            handleChange={e => handleChangeBusiness(e, 0)}
            error={errors?.business && errors.business}
          />
        </div>

        <div className="user-form-field" data-automation-id="input-nodes">
          <MultiSelectGeneric
            id="nodes"
            items={selectedBusinesses[0].nodes}
            required={true}
            label="Sucursales"
            selected={selectedBusinesses[0].selectedNodes}
            multiple={true}
            handleChange={e => handleChangeNodes(e, 0)}
            error={errors?.nodes && errors.nodes}
          />
        </div>
      </div>

      {/* <div className="user-form-field image" data-automation-id="input-image">
        <DragAndDropImage
          name="image"
          isSmall={true}
          handleChange={handleSelectImage}
          handleDelete={handleDeleteImage}
          file={user?.image}
          error={errors?.image && errors.image}
        />
      </div> */}

      <div className="user-form-submit">
        <div
          className={`user-form-submit-button ${
            continueEnabled ? "enabled" : ""
          }`}
          onClick={continueEnabled ? e => handleSubmit(e) : null}
        >
          {mode === "edit" ? "GUARDAR" : "REGISTRAR USUARIO"}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
