import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FileSaver from "file-saver";
import i18n from "i18n";

import { OrderService } from "../../../../../services/OrderService";
// import { EventRepository } from 'helpers/EventRepository';
import { EventRepository } from "helpers/EventRepository";

import "./styles.scss";
import AdminLayout from "../../../../../components/MainApp/layouts/DesktopLayout";
import OrderDetailDescriptionPanel from "../../../../../components/MainApp/organisms/Orders/RequestOrders/Detail/Description";
import OrderProductSummary from "../../../../../components/MainApp/organisms/Orders/RequestOrders/ProductSummary";
import OrderPills from "../../../../../components/MainApp/atoms/Pills/OrderPills";
import MainButton from "../../../../../components/MainApp/atoms/RoundedButton";
import RejectModal from "../../../../../components/MainApp/organisms/Orders/RequestOrders/Modals/RejectModal";
import TwoOptionModal from "../../../../../components/MainApp/atoms/Modals/TwoOptionModal";
import Loader from "../../../../../components/MainApp/atoms/Loader";
import { isMobile } from "../../../../../helpers/Mobile";
import { ParseDateTime } from "helpers/Parsers";

export const RelocationOrderDetailsView = props => {
  const history = useHistory();

  const [order, setOrder] = useState(null);
  const [orderDescription, setorderDescription] = useState([]);
  const [modalOpen, setmodalOpen] = useState(false);
  const [loading, setloading] = useState(false);
  const [products, setproducts] = useState([]);
  const [recOrder, setRecOrder] = useState({});

  const headerOptions = isMobile
    ? [
        {
          icon: "icon-iconos_descargar",
          name: "Descargar",
          handler: () => {
            alert("hi");
          },
          tooltip: "Descargar"
        }
      ]
    : [];

  const goBackModal = () => {
    history.push("/admin/orders/relocation");
  };

  useEffect(() => {
    const id = props.match.params.key;
    setloading(true);
    OrderService.relocationOrder(id).then(response => {
      setloading(false);
      setOrder(response);
      const orderDescriptionNew = formatOrderForDescription(response);
      setorderDescription(orderDescriptionNew);

      if (["DISP", "COM"].includes(response.status)) {
        OrderService.recOrders({ initial_order: response.id }).then(
          response => {
            if (response.results.length > 0) {
              setRecOrder(response.results[0]);
            }
          }
        );
      }
    });
  }, []);

  useEffect(() => {
    const id = props.match.params.key;
    OrderService.getItems(id).then(response => {
      let productList = response.map(item => {
        let newItem;
        newItem = item.item;
        newItem.units = item.quantity;
        newItem.code = item.item.sku;

        newItem.stock = 1;
        return item.item;
      });
      setproducts(productList);
    });
  }, []);

  const formatOrderForDescription = newOrder => {
    return [
      {
        title: "Número de orden",
        value: newOrder.code
      },
      {
        title: "Fecha de creación",
        value: ParseDateTime(newOrder.created_at)
      },
      {
        title: "origen",
        value: newOrder["origin"] && newOrder["origin"].name
      },
      {
        title: "destino",
        value: newOrder["destination"] && newOrder["destination"].name
      },
      {
        title: "responsable",
        value: newOrder["owner"] && newOrder["owner"].name
      }
    ];
  };

  const handleRejectModalOpen = () => {
    setmodalOpen(true);
  };

  const handleCloseModal = () => {
    setmodalOpen(false);
  };

  const handleReject = reason => {
    setloading(true);
    OrderService.changeOrderStatus(order.id, "REJ", reason)
      .then(response => {
        setmodalOpen(false);
        setloading(false);
        EventRepository.notificationSend({
          label: i18n.t("La orden se rechazó de manera exitosa."),
          type: "success"
        });
        props.history.push("/admin/orders/relocation");
      })
      .catch(error => {
        EventRepository.notificationSend({
          label: i18n.t("Hubo un error al rechazar la orden."),
          type: "error"
        });
      });
  };

  const handleApprove = () => {
    setloading(true);
    OrderService.changeOrderStatus(order.id, "PRO", "apporoved")
      .then(response => {
        setloading(false);
        setmodalOpen(false);

        EventRepository.notificationSend({
          label: i18n.t("La orden se aprobó de manera exitosa."),
          type: "success"
        });
        props.history.push("/admin/orders/relocation");
      })
      .catch(error => {
        EventRepository.notificationSend({
          label: i18n.t("Hubo un error al aprobar la orden."),
          type: "error"
        });
      });
  };

  const downloadVoucher = () => {
    setloading(true);
    OrderService.relocationDownloadVoucher(order.id)
      .then(blob => {
        setloading(false);
        FileSaver.saveAs(blob, "comprobante.pdf");
      })
      .catch(error => {
        setloading(false);
      });
  };

  const goToReceptionOrder = () => {
    props.history.push(`/admin/orders/relocation/details/${recOrder.id}/`);
  };

  const customButtons = (
    <div className="relocation-order-details-custom-buttons">
      <div className="relocation-order-details-reject">
        <MainButton
          handleClick={handleRejectModalOpen}
          state={"enabled"}
          legend="RECHAZAR"
        ></MainButton>
      </div>

      <div className="relocation-order-details-approve">
        <MainButton
          handleClick={handleApprove}
          state={"enabled"}
          legend="APROBAR"
        ></MainButton>
      </div>
    </div>
  );

  const loadedContent = (
    <div className="relocation-order-details-wrapper">
      <div className="relocation-order-details-title-wrapper">
        <div className="relocation-order-details-left-title">
          <div className="relocation-order-details-title">
            Orden de recepción
          </div>
          <div className="pill-wrapper">
            {order && <OrderPills big={true} state={order.status}></OrderPills>}
          </div>
        </div>

        <div className="relocation-order-details-right-title">
          {order && order.status === "DISP" && (
            <div
              style={{ display: "flex", alignItems: "center" }}
              onClick={goToReceptionOrder}
            >
              <i className="relocation-order-details-order-rec-icon icon-iconos_documento"></i>
              <div className="relocation-order-details-order-rec-text">
                Ver orden de recepción
              </div>
            </div>
          )}
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={downloadVoucher}
          >
            <i className="relocation-order-details-download-icon icon-iconos_descargar"></i>
            <div className="relocation-order-details-download-text">
              Descargar detalle
            </div>
          </div>
        </div>
        {!isMobile && order && order.status === "PEN" &&(
          <div className="relocation-order-details-button">
            {customButtons}
          </div>
        )}

      </div>
      <div className="relocation-order-details-panels">
        <div className="relocation-order-details-left">
          <div className="relocation-order-details-panel">
            <OrderDetailDescriptionPanel
              order={orderDescription}
            ></OrderDetailDescriptionPanel>
          </div>
        </div>
        <div className="relocation-order-details-right">
          <div className="relocation-order-details-panel">
            <OrderProductSummary
              products={products}
              edit={false}
              paginationSize={5}
            ></OrderProductSummary>
          </div>
        </div>
      </div>
      {isMobile && order && order.status === "PEN" && (
        <div className="relocation-order-details-button">
          {/* <div className="relocation-order-details-button-inner">
              <MainButton legend="Guardar" type="submit" handleClick={handleApprove} state={"enabled"}></MainButton>
            </div> */}
          {customButtons}
        </div>
      )}
      <RejectModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleReject={handleReject}
      ></RejectModal>
    </div>
  );

  const content = loading ? <Loader /> : loadedContent;

  return (
    <div className="relocation-order-details-container">
      <AdminLayout
        headerTitle={"Detalle de orden"}
        headerOptions={headerOptions}
        content={content}
        goBackFunc={goBackModal}
        navHidden={true}
      ></AdminLayout>
    </div>
  );
};

export default RelocationOrderDetailsView;
