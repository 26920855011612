import React, {useEffect, useState} from "react";
import {useHistory} from 'react-router-dom'

import useRequest from 'hooks/useRequest';

import i18n from "i18n";
import { EventRepository } from 'helpers/EventRepository';

import { steps } from './data/steps';
import Loader from "components/MainApp/atoms/Loader";
import AdminLayout from 'components/MainApp/layouts/DesktopLayout';
import StepProgress from 'components/MainApp/atoms/Wizard/StepProgress';
import StepOne from './Wizard/Step1';
import StepTwo from './Wizard/Step2';
import StepThree from  './Wizard/Step3';

import './styles.scss';
import { BusinessService } from "services/BusinessService";
import { PublicService } from 'services/PublicService.jsx';
import { AuthService } from 'services/AuthService';

const initialBusiness = {
    id: '',
    name: '',
    business_name: '',
    document: '',
    sector: '',
    country: '',
    region: '',
    state: '',
    locality: '',
    currency: '',
    image: '',
    address: '',
    phone: '',
    email: '',
}

const AddBusinessView = (props) => {
    const history = useHistory();
    const {
        loading, beforeSubmit, afterSubmit,
        errors, dealWithError
    } = useRequest();
    const [activeStep, setActiveStep] = useState('0');
    const [business, setBusiness] = useState(initialBusiness);
    const [countries, setcountries] = useState([]);
    const [regions, setregions] = useState([]);
    const [provinces, setprovinces] = useState([]);
    const [cities, setcities] = useState([]);

    const headerOptions = [];

    useEffect(() => {
        let countriesAux = []
        PublicService.countries().then((response) => {
            countriesAux = response
            setcountries(response)
          });
    }, [])

    const analizeErrors = (message, errors = {}) => {
        if (Object.keys(errors).length === 0) {
            EventRepository.notificationSend({
              label: message,
              type: 'error'
            });
        }

        if(['name', 'business_name', 'document', 'type', 'country', 'region', 'state', 'locality', 'currency', 'image'].includes(Object.keys(errors)[0])){
            setActiveStep('0')
        }
        if(['address', 'phone', 'email'].includes(Object.keys(errors)[0])){
            setActiveStep('1')
        }
    }

    const handleFinish = (e) => {
        e.preventDefault();
        beforeSubmit();

        let formData = new FormData();
        ['name', 'business_name', 'document', 'type', 'sector','address', 'phone', 'email'].map(key =>
            formData.append(key, business[key])
        )
        formData.append("country", business.country ? business.country.value : '')
        formData.append("state", business.state ? business.state.value : '')
        formData.append("region", business.region ? business.region.value : '')
        formData.append("locality", business.locality ? business.locality.value : '')
        formData.append("currency", business.currency ? business.currency.value : '')
        formData.append("instance_id", AuthService.currentUserValue().account.instances[0].id)
        if (business.image) {
            formData.append("image", business.image);
          }

        BusinessService.createBusinessFormData(formData).then( business =>{
            // setBusiness(business)
            afterSubmit();
            history.push(`/admin/businesses`)
        }).catch(error => {
            afterSubmit();
            dealWithError(error, 'generic.error', (message, errors) => {
                analizeErrors(message, errors);
            });
        })
    }

    const goToList = () => {
        history.push(`/admin/businesses`)
    }

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let _business = {...business}
        _business[name] = value;

        setBusiness(_business)
    }

    const handleSelect = (selectedOption, type) => {
        let _business = {...business}
        switch (type) {
            case 'country':
                _business.country = selectedOption;
                _business.region = ''
                _business.state = ''
                _business.locality = ''
                PublicService.regions(_business.country.value).then(response => {
                    setregions(response)
                });
                setBusiness(_business);
                break;
            case 'region':
                _business.region = selectedOption;
                _business.state = ''
                _business.locality = ''
                PublicService.states(_business.country.value, _business.region.value).then(response => {
                    setprovinces(response)
                });
                setBusiness(_business);
                break;
            case 'state':
                _business.state = selectedOption;
                _business.locality = ''
                PublicService.cities(_business.country.value, _business.region.value, _business.state.value).then(response => {
                    setcities(response)
                });
                setBusiness(_business);
                break;
            case 'city':
                _business.locality = selectedOption;
                setBusiness(_business);
                break;

            default:
                _business[type] = selectedOption;
                setBusiness(_business);

                    break;
        }
    }

  const handleDeleteImage = (e) => {
    let _business = {...business};
    _business["image"] = null;
    setBusiness(_business)
  };

  const handleChangeImmage = (e) => {
    let _business = {...business};
    _business.image = e.target.files[0];
    setBusiness(_business)

  };

    const validateStep0 = () => {
      if (business.country?.value === 'ES') {
        return ['name', 'business_name', 'document', 'country', 'region', 'state', 'locality', 'currency'].every(key => {
          if (key === 'country' || key === 'region' || key === 'locality' || key === 'state' || key === 'currency') {
            return business[key] && business[key].value && business[key].value.trim() !== ''
          } else {
            return business[key] && business[key].trim() !== ''
          }
        })
      }
      return ['name', 'business_name', 'document', 'country', 'currency' ].every(key => {
        if (key === 'country' || key === 'region' || key === 'currency') {
          return business[key] && business[key].value && business[key].value.trim() !== ''
        } else {
          return business[key] && business[key].trim() !== ''
        }
      })
    }

    const validateStep1 = () => {
        return ['phone', 'email' ].every(key => {
          return business[key] && business[key].trim() !== ''
        })
    }

    const goToStep = (step) => {
        setActiveStep(step)
    }

    const getConfirmationView = () => {

    }

    const headerTitle = 'Nueva empresa';

    const step0 = <StepOne handleContinue={() => setActiveStep('1')}
        business={business}
        handleChange={handleChange}
        handleChangeImg={handleChangeImmage}
        handleDeleteImg={handleDeleteImage}
        handleSelect={handleSelect}
        enableContinue={validateStep0()} error={errors}
        countries={countries}
        regions={regions}
        provinces={provinces}
        cities={cities}></StepOne>

    const step1 = (
      <StepTwo
        handleContinue={() => setActiveStep("2")}
        goBack={() => goToStep("0")}
        handleChange={handleChange}
        handleSelect={handleSelect}
        enableContinue={validateStep1()}
        business={business}
        error={errors}
      ></StepTwo>
    );

    const step2 = (
      <StepThree
        handleFinish={handleFinish}
        goBack={() => goToStep("1")}
        business={business}
      ></StepThree>
    );

    // const stepList = [step0, step1, step2];
    const stepList = {
        '0': step0,
        '1': step1,
        '2': step2,
    }

    const content = <div className={`add-business-content`}>
        <div className={`add-business-wrapper`}>
            <StepProgress steps={steps} activeStep={activeStep}/>
            <div className={`add-business-step-wrapper`}>
                {stepList[activeStep]}
            </div>
        </div>
    </div>

    return(
        <div className="add-business-container">
        <AdminLayout
          headerTitle={i18n.t(headerTitle)}
          headerOptions={headerOptions}
          content={content}
          navHidden={true}
          goBackFunc={goToList}
        ></AdminLayout>
        {loading && <Loader />}
      </div>
    )
}

export default AddBusinessView;