import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import i18n from "i18n";

import useRequest from "hooks/useRequest";
import { ApplicationService } from "services/ApplicationService";

import "./styles.scss";
import AdminLayout from "components/MainApp/layouts/DesktopLayout";
import Loader from "components/MainApp/atoms/Loader";
import AddAppComponent from "../components/AppAdd";
import SentSuccess from "../components/AppAddSuccess";

const initialApp = {
  name: "",
  client_id: null,
  client_secret: null,
  connected: false,
  status: "NOT",
  scopes: [],
  callback_url: "",
};

export const AddApplication = () => {
  const history = useHistory();

  const {
    loading,
    beforeSubmit,
    afterSubmit,
    errors,
    dealWithError,
  } = useRequest();

  const [sentSuccess, setSentSuccess] = useState(false);
  const [app, setApp] = useState(initialApp);
  const [createdApp, setCreatedApp] = useState(null);
  const [buttonEnabled, setButtonEnabled] = useState(false);

  useEffect(() => {
    verifySubmitable();
  }, [app]);

  const goBack = () => {
    history.push("/admin/applications");
  };

  const addNewApplication = () => {
    setSentSuccess(false);
    setApp({
      name: "",
      client_id: null,
      client_secret: null,
      connected: false,
      status: "NOT",
      scopes: [],
      callback_url: "",
    });
    setCreatedApp(null);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const _app = { ...app };
    if (e.target.type !== "file") {
      _app[name] = e.target.value;
    } else {
      _app[name] = e.target.files[0];
    }

    setApp(_app);
  };

  const handleChangeImage = (e) => {
    const _app = { ...app };
    _app.logo_file = e.target.files[0];

    setApp(_app);
  };

  const handleDeleteImage = (e) => {
    const _app = { ...app };
    _app.logo_file = null;

    setApp(_app);
  };

  const handleSelect = (scope) => {
    const _app = { ...app };

    if (_app.scopes.includes(scope)) {
      _app.scopes = _app.scopes.filter((s) => s !== scope);
    } else {
      _app.scopes.push(scope);
    }
    setApp(_app);
  };

  const verifySubmitable = () => {
    const state = app.name && app.callback_url && app.scopes.length > 0;
    setButtonEnabled(state);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const _app = { ...app };
    _app.scope = _app.scopes.join(" ");

    let formData = new FormData();
    formData.append("name", _app.name);
    formData.append("callback_url", _app.callback_url);
    formData.append("scope", _app.scope);
    formData.append("enabled", true);

    if (_app.logo_file !== undefined) {
      formData.append("logo", _app.logo_file ? _app.logo_file : "");
    }

    beforeSubmit();
    ApplicationService.createApplication(formData)
      .then((application) => {
        afterSubmit();
        setSentSuccess(true);
        setCreatedApp(application);

        console.log(createdApp, application);
      })
      .catch((error) => {
        afterSubmit();
        dealWithError(error, "Error creando la aplicacion");
      });
  };

  const content = (
    <div className="add-application-wrapper">
      <AddAppComponent
        app={app}
        errors={errors}
        handleChange={handleChange}
        handleChangeImage={handleChangeImage}
        handleDeleteImage={handleDeleteImage}
        handleSelect={handleSelect}
      ></AddAppComponent>
      <div className="button-submit-container">
        <button
          className={`button-submit ${buttonEnabled && "enabled"}`}
          disabled={!buttonEnabled}
          onClick={handleSubmit}
        >
          REGISTRAR APP
        </button>
      </div>
    </div>
  );

  const success = (
    <div className="add-success-application-wrapper">
      {createdApp && (
        <SentSuccess
          app={createdApp}
          boldButtonFunc={addNewApplication}
        ></SentSuccess>
      )}
    </div>
  );
  return (
    <div className="add-application-container">
      <AdminLayout
        headerTitle={i18n.t("Nueva Aplicación")}
        headerOptions={[]}
        content={sentSuccess ? success : content}
        goBackFunc={goBack}
        navHidden={true}
      ></AdminLayout>

      {loading && <Loader />}
    </div>
  );
};

export default AddApplication;
